<!-- 账户 -->
<template>
  <div>
    <div>
      <!-- 面包屑导航 -->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>员工管理</el-breadcrumb-item>
      </el-breadcrumb>

      <!-- 卡片视图 -->
      <el-card>
        <!-- 搜索与添加区域 -->
        <el-row :gutter="20">
          <el-col :span="9">
            <el-input
              placeholder="请输入内容"
              v-model="queryInfo.query"
              clearable
              @clear="getStaffList"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getStaffList"
              ></el-button>
            </el-input>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="addDialogVisible = true;title='添加员工'">添加员工</el-button>
          </el-col>
        </el-row>

        <!-- 表格区域 -->
        <el-table :data="staffList" border stripe>
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="name" label="管理员名称"> </el-table-column>
          <el-table-column prop="type_str" label="类型"> </el-table-column>
          <el-table-column prop="tel" label="电话"> </el-table-column>
          <el-table-column prop="status" label="状态">
            <template v-slot:default="scope">
              <el-switch
                v-model="scope.row.status"
                @change="updateInfo(scope.row.ID, scope.row.status)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180px">
            <template v-slot:default="scope">
              <!-- {{scope.row}} -->
              <!-- 作用域插槽 -->
              <!-- 修改按钮 scope.row.id -->
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="small"
                @click="showEditDialog(scope.row.ID)"
              ></el-button>
              <!-- 删除按钮 -->
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="small"
                @click="deleteStaff(scope.row.ID)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pagenum"
          :page-size="queryInfo.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>

        <!-- 添加员工对话框 -->
        <el-dialog
          :title="title"
          :visible.sync="addDialogVisible"
          width="50%"
          @closed="addHandleClose"
        >
          <el-form
            :model="addForm"
            :rules="formRules"
            ref="addFormRef"
            label-width="100px"
          >
            <el-form-item label="管理员名称" prop="name">
              <el-input v-model="addForm.name"></el-input>
            </el-form-item>
            <el-form-item label="账号" prop="account">
              <el-input v-model="addForm.account" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input :type="typePwd" v-model="addForm.password" autocomplete="off"
                ><el-button
                  slot="append"
                  icon="el-icon-view"
                  circle
                  @click="showPwd"
                ></el-button
              ></el-input>
            </el-form-item>
            <el-form-item label="员工电话" prop="tel">
              <el-input v-model="addForm.tel"></el-input>
            </el-form-item>
            <el-form-item label="员工权限" prop="menuList">
              <el-checkbox-group v-model="addForm.menuList" >
                <el-checkbox v-for="item in menuList" :label="item['ID']" :key="item.ID">{{item['菜单名称']}}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="员工身份" prop="type">
              <el-radio-group v-model="addForm.type" >
                <el-radio v-for="item in [{label:'渠道商',value:'6'},{label:'售票员',value:'1'},{label:'验票员',value:'2'},{label:'操作员',value:'3'},{label:'管理员',value:'4'}]" :label="item['value']" :key="item.value">{{item['label']}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <span slot="footer">
            <el-button @click="addDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="title==='添加员工'?addStaff():changeStaff()">确 定</el-button>
          </span>
        </el-dialog>

      </el-card>
    </div>
  </div>
</template>

<script>
import Qs from 'qs'

export default {
  // name: "staff",
  data() {
    return {
      queryInfo: {
        query: "",
        // 当前的页数
        pagenum: 1,
        pagesize: 5,
      },
      total: 0,
      staffList: [],
      menuList:[],
      title:'添加员工',
      // 添加员工弹框是否展示
      addDialogVisible: false,
      addForm: {
        name: "",
        account: "",
        password: "",
        tel: "",
        EntityStatus: "",
        type: "",
        menuList:[]
      },
      // 展示密码
      typePwd: "password",
      formRules:{
        name: [{ required: true, message: "请输入渠道名称", trigger: "blur" }],
        account: [{ required: true, message: "请输入渠道登录账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入渠道登录密码", trigger: "blur" }, { min: 6, message: '长度最少6个字符', trigger: 'blur' }],
        menuList:[{ type: 'array', required: true, message: '请至少选择一个栏目', trigger: 'change' }]
      }
    };
  },
  created() {
    this.getStaffList();
    this.getMenuList()
  },
  methods: {
    getMenuList(){
      this.$axios({
        method: "get",
        url: "/system/getMenuList",
        data: this.queryInfo
      })
        .then((res) => {
          // console.log(res.data.employees);
          this.menuList=res.data.filter(v=>{return !!v['菜单路由']})
        })
        .catch((err) => err);
    },
    getStaffList() {
      this.$axios({
        method: "post",
        url: "/manager/staff",
        data: this.queryInfo,
      })
        .then((res) => {
          console.log(res);
          // console.log(res.data.employees);
          this.total = res.total;
          this.staffList = res.data.map(v=>{
            v.status=v.EntityStatus===1
            let type_str=''
            switch (Number(v.type)){
              case 1:type_str='售票员';break;
              case 2:type_str='验票员';break;
              case 4:type_str='管理员';break;
              case 3:
              case 5:type_str='操作员';break;
              case 6:type_str='渠道商';break;
              default:type_str='未知';break
            }
            v.type_str=type_str
            return v
          });
          // console.log(this.staffList);
          // console.log(res.data.employees);
        })
        .catch((err) => err);
    },
    //页码改变函数
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      this.getStaffList();
    },
    //关闭添加员工弹框
    addHandleClose() {
      this.confirmPwd = "";
      this.addForm={
        name: "",
        account: "",
        password: "",
        tel: "",
        EntityStatus: "",
        type: "",
        menuList:[]
      }
      this.$refs.addFormRef.resetFields();
    },
    // 展示密码
    showPwd() {
      this.typePwd = this.typePwd === "password" ? "text" : "password";
    },
    // 添加员工
    addStaff() {
      // const formData = new FormData()
      // formData.append('role', 1)

      this.$refs.addFormRef.validate((valid) => {
        if (!valid) return;
        this.$axios({
          method: "post",
          url: "/manager/addStaff",
          data: this.addForm,
          // headers:{
          //   'Content-Type': 'application/x-www-form-urlencoded'
          // }
        })
          .then((res) => {
            if (res.code == 200) {
              this.$message.success("添加用户成功");
              this.addDialogVisible = false;
              this.getStaffList();
            } else {
              this.$message.warning(res.msg);
            }
          })
          .catch((err) => err);
      });
    },
    //删除员工
    deleteStaff(id) {
      // console.log(id);
      this.$confirm("此操作将删除该员工, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.$axios({
            method: "post",
            url: "/manager/editStaff",
            data:{
              ID:id,
              isDelete:1
            }
          }).then(res=>{
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getStaffList();
            } else {
              this.$message.warning(res.msg);
            }

          })

        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //状态改变函数
    updateInfo(id, status) {
      status = status == false ? 0 : 1;
      // console.log(status);
      let emp_status = { status: status };
      console.log(emp_status);

      this.$axios({
        method: "post",
        url: "/manager/editStaff",
        data:{
          ID:id,
          update:1,
          status:status
        }
      }).then(res=>{
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "修改成功!",
          });
          this.getStaffList();
        } else {
          this.$message.warning(res.msg);
        }
      })
        .catch((err) => err);
    },
    //修改员工信息
    changeStaff() {
      console.log(9999)
      this.$refs.addFormRef.validate((valid) => {
        if (!valid) return;
        this.$axios({
          method: "post",
          url: "/manager/editStaff",
          data: this.addForm,
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "更新员工信息成功!",
              });
              this.addDialogVisible = false;
              this.getStaffList();
            } else {
              this.$message.warning(res.msg);
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      });
    },
    //监听对话框打开与关闭
    showEditDialog(id) {
      this.title='编辑员工'
      this.addDialogVisible = true;
      this.addForm=Object.assign({},this.staffList.find(v=>v.ID===id))
    },
  },
};
</script>

<style scoped>
</style>




